import React from 'react'
import { Typography, Box, Container, Grid } from '@mui/material'
import eyeImage from '../../../images/eyeImage.png'
import gorkiImage from '../../../images/gorki.jpg'
import gorkiPhoto from '../../../images/fotogorki.jpg'

export default function Secret() {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box sx={{ minWidth: '100%', minHeight: '100px', backgroundColor: '#3692E9', textAlign: 'center' }}>
        <Typography variant="h5" pt={4} weight='light' style={{ color: 'white', fontWeight: 'bold' }}>
          Наши услуги
        </Typography>
      </Box>

      <Container sx={{ backgroundColor: '#FFFFFF' }}>
        <Grid container>
          <Grid item>
            <img
              src={eyeImage}
              style={{ maxWidth: '60px', maxHeight: '60px', margin: '30px' }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item pt={5}>
            <Typography variant="h4" style={{ color: 'black', fontWeight: 'bold', alignItems: 'center' }}>
              Тайный покупатель
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: '50px' }}>
        <Grid container justifyContent="space-between">
          <Grid sx={{ float: 'left', width: '400px', }}>

            <img
              src={gorkiPhoto}
              style={{ width: '330px', height: '240px' }}
              alt=""
            />

          </Grid>
          <Grid sx={{ float: 'left', width: '600px' }}>
            <img
              src={gorkiImage}
              style={{ maxWidth: '150px', maxHeight: '150px', margin: '30px' }}
              loading="lazy"
              alt=""
            />
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
              НАПРИМЕР:
            </Typography>
            <Typography variant='h6' sx={{ mt: '30px', mb: '30px' }} color='#838383'>
              В 2015 г. в Фермерских лавках «Ближние Горки» наше агентство проводило программу «Таинственный покупатель» – оценку соблюдения точками продаж заданных стандартов торговли и обслуживания клиентов.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

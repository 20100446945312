import React from 'react'
import { Typography, Box, Container, Grid, ImageList, ImageListItem  } from '@mui/material'
import confImage from '../../../images/confImage.png'
import conf1 from '../../../images/conf1.jpg'
import conf2 from '../../../images/conf2.jpg'
import conf3 from '../../../images/conf3.jpg'
import conf4 from '../../../images/conf4.jpg'
import conf5 from '../../../images/conf5.jpg'

const itemData = [
  {
    img: conf1,
    title: 'conf1',
  },
  {
    img: conf2,
    title: 'conf2',
  },
  {
    img: conf3,
    title: 'conf3',
  },
  {
    img: conf4,
    title: 'conf4',
  },
  {
    img: conf5,
    title: 'conf5',
  },
]

export default function Conference() {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box sx={{ width: '100%', height: '100px', backgroundColor: '#3692E9', textAlign: 'center' }}>
        <Typography variant="h5" pt={4} weight='light' style={{ color: 'white', fontWeight: 'bold' }}>
          Наши услуги
        </Typography>
      </Box>

      <Container sx={{ width: '100%', minHeight: '100px', backgroundColor: '#FFFFFF' }}>
        <Grid container>
          <Grid item>
            <img
              src={confImage}
              style={{ maxWidth: '60px', maxHeight: '60px', margin: '30px' }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item pt={5}>
            <Typography variant="h4" style={{ color: 'black', fontWeight: 'bold', alignItems: 'center' }}>
              Организация конференций, конвенций, team building
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ mt: '30px' }}>
        <Grid container justifyContent="space-between">
          <Grid sx={{ float: 'left', width: '500px', }}>
            <Typography variant='h6' color='#838383'>
              Осуществляем полный цикл работ по организации участия наших клиентов в выставках (от аренды выставочной площадки, разработки стенда и его застройки, промо поддержки, заключения договоров на оптовые продажи продукции, до анализа эффективности участия в выставке)
            </Typography>
            <Typography variant='h6' color='#838383' mt={5}>
              Например, мы проводили подобные работы на выставке PRODEXPO для компаний Food Union, Kraft Foods и т.д.; для MAREVEN FOOD CENTRAL - на выставке Индустрии быстрого питания IFFF и METRO EXPO.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: '50px' }}>
        <Typography variant='h5' fontWeight='bold'>
          Примеры работ:
        </Typography>
        <ImageList sx={{ width: '100%', height: '100%', mb: 5, mt: 5 }} variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=161&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Container>
    </Box>
  )
}

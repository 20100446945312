import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import News from "../Pages/News";
import About from "../Pages/About";
import Servises from "../Pages/Servises";
import Contacts from "../Pages/Contacts";
import OurClients from "../Pages/OurClients";
import Instruments from "../Pages/Instruments"
import Consumerpromo from "../Pages/Uslugi/Consumerpromo";
import Tradepromo from "../Pages/Uslugi/Tradepromo";
import Merch from "../Pages/Uslugi/Merch";
import Specialprojects from "../Pages/Uslugi/Specialprojects";
import Conference from "../Pages/Uslugi/Conference";
import Direct from "../Pages/Uslugi/Direct";
import Development from "../Pages/Uslugi/Development";
import Secret from "../Pages/Uslugi/Secret";



const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/news" element={<News />} />
      <Route path="/servises" element={<Servises />} />
      <Route path="/ourclients" element={<OurClients />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/instruments" element={<Instruments />} />
      <Route path="/consumerpromo" element={<Consumerpromo />} />
      <Route path="/tradepromo" element={<Tradepromo />} />
      <Route path="/development" element={<Development />} />
      <Route path="/merch" element={<Merch />} />
      <Route path="/direct" element={<Direct />} />
      <Route path="/conference" element={<Conference />} />
      <Route path="/specialprojects" element={<Specialprojects />} />
      <Route path="/secret" element={<Secret />} />
    </Routes>
  );
};

export default AppRouter;

import React from 'react'
import { Typography, Box, Container, Grid } from '@mui/material'
import devImage from '../../../images/devImage.png'

export default function Development() {
  return (
    <Box sx={{minHeight: '100vh'}}>
      <Box sx={{ width: '100%', height: '100px', backgroundColor: '#3692E9', textAlign: 'center' }}>
        <Typography variant="h5" pt={4} weight='light' style={{ color: 'white', fontWeight: 'bold' }}>
          Наши услуги
        </Typography>
      </Box>

      <Container sx={{ width: '100%', minHeight: '100px', backgroundColor: '#FFFFFF' }}>
        <Grid container>
          <Grid item>
            <img
              src={devImage}
              style={{ maxWidth: '60px', maxHeight: '60px', margin: '30px' }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item pt={5}>
            <Typography variant="h4" style={{ color: 'black', fontWeight: 'bold', alignItems: 'center' }}>
              Разработка/поставка POSM, униформы, оборудования
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ mt: '30px' }}>
        <Grid container justifyContent="space-between">
          <Grid sx={{ float: 'left', width: '500px', }}>
            <Typography variant='h6' color='#838383'>
              Мы занимаемся поставкой и разработкой:
              <ul>
                <li>POSM;</li>
                <li>Торгового оборудования;</li>
                <li>Оборудования для проведения рекламных акций;</li>
                <li>Призового фонда для рекламных акций;</li>
                <li>Сувенирной продукции;</li>
                <li>Униформы;</li>

              </ul>
            </Typography>
          </Grid>
          <Grid sx={{ float: 'left', width: '500px' }}>
            <Typography variant='h6' color='#838383' mt={7}>
              Мы работаем напрямую с заводами в России,
              без посредников. Контролируем процесс производства с момента разработки эскиза и
              до отгрузки продукции заказчику, что позволяет нам поставлять продукцию высокого качества, по оптимальной цене, в кратчайшие сроки
              (по всем этим параметрам, наша продукция выигрывает у китайских аналогов).
            </Typography>
            <Typography variant='h6' color='#838383' sx={{mt: '30px'}}>
              Призы, которые мы поставляем для проведения рекламных акций, снабжаются сертификатами качества и необходимой документацией, принятой на территории РФ (например, промо посуда из фарфора, которую мы изготавливаем для рекламных акций, сертифицирована и может использоваться при кормлении детей).
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: '150px' }}>
        {/* <Typography variant='h5' fontWeight='bold'>
          Примеры работ:
        </Typography> */}
      </Container>
    </Box>
  )
}

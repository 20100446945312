import React from 'react'
import { Typography, Box, Container, Grid } from '@mui/material'
import heartImage from '../../../images/heartImage.png'

export default function Direct() {
  return (
    <Box sx={{minHeight: '100vh'}}>
      <Box sx={{ width: '100%', height: '100px', backgroundColor: '#3692E9', textAlign: 'center' }}>
        <Typography variant="h5" pt={4} weight='light' style={{ color: 'white', fontWeight: 'bold' }}>
          Наши услуги
        </Typography>
      </Box>

      <Container sx={{ width: '100%', minHeight: '100px', backgroundColor: '#FFFFFF' }}>
        <Grid container>
          <Grid item>
            <img
              src={heartImage}
              style={{ maxWidth: '60px', maxHeight: '60px', margin: '30px' }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item pt={5}>
            <Typography variant="h4" style={{ color: 'black', fontWeight: 'bold', alignItems: 'center' }}>
              Direct Marketing и Loyalty Club
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: '30px' }}>
        <Grid container justifyContent="space-between">
          <Grid sx={{ float: 'left', width: '500px', }}>
            <Typography variant='h6' color='#838383'>
              <ul>
                <li>  Персонализированная
                  и неперсонализированная почтовая,
                  e-mail рассылка;</li>
                <li>Опросы, информирование или продажи
                  по телефону;</li>
                <li>Организация горячих телефонных линий;
                  создание Mail house;</li>
                <li>Рассылка пробных экземпляров товаров;
                  курьерская доставка;</li>
                <li>Персональная пропаганда;</li>
                <li>Консультирование;</li>
              </ul>
            </Typography>
          </Grid>
          <Grid sx={{ float: 'left', width: '500px', mb: '40px' }}>
            <Typography variant='h6' color='#838383'>
              <ul>
                <li>  Разработка, организация и создание клубов лояльности марке (Loyalty Club);</li>
                <li>Организация и проведение национальных лотерей, построенных по принципу «пришлите 3, 5, 10 штрих кодов от продукции n, и получите призы….» (включая перевод адресной информации из рукописного в электронный вид, разработку эксклюзивного программного обеспечения, розыгрыш призов по случайной выборке).</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

import React from "react";
import CardNews from "../HomePages/Blocks/NewsBlock";
import PartnersBlock from "../HomePages/Blocks/PartnersBlock";
import CardsBlock from "../HomePages/Blocks/CardsBlock";
import StatisticBlock from "../HomePages/Blocks/StatisticBlock";
import PictureCard from "../HomePages/Blocks/PictureCard";



const Home = () => {
  return (
    <>  
        <PictureCard/>     
        <CardsBlock/>
        <StatisticBlock/>
        {/* <CardNews /> */}
        <PartnersBlock />      
    </>
  );
};

export default Home;

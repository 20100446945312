import * as React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import trubaImage from '../../../images/trubaImage.png'
import heartImage from '../../../images/heartImage.png'
import confImage from '../../../images/confImage.png'
import merchImage from '../../../images/merchImage.png'
import devImage from '../../../images/devImage.png'
import eyeImage from '../../../images/eyeImage.png'
import discountImage from '../../../images/discountImage.png'
import dimondImage from '../../../images/dimondImage.png'
import { Grid, Container, Box, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function CardsBlock() {

  const navigate = useNavigate();
  const goConsumerPromo = () => navigate('/consumerpromo')
  const goTradePromo = () => navigate('/tradepromo')
  const goMerchPromo = () => navigate('/merch')
  const goSecret = () => navigate('/secret')
  const goDirectMarketing = () => navigate('/direct')
  const goDevelopment = () => navigate('/development')
  const goConference = () => navigate('/conference')
  const goSpecial = () => navigate('/specialprojects')


  return (
    <Box sx={{ backgroundColor: '#E6E6E6' }}>
      <Container sx={{ pt: 5, pb: 5 }} >
        <Grid container spacing={2} sx={{ display: 'flex' }} >
          <Grid item xs={12} sm={4} md={3}>
            <CardActionArea>
              <Card onClick={(goConsumerPromo)} variant="outlined" sx={{ minHeight: '320px' }}>
                <CardOverflow>
                  <img
                    src={discountImage}
                    style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                    loading="lazy"
                    alt=""
                  />
                </CardOverflow>
                <CardContent>
                  <Typography level="h3">Consumer promo</Typography>
                  <Typography level="body-sm">
                    Раздача листовок, дегустации, персональная пропаганда и т.д. Стимулирование продаж посредством организации Центров выдачи призов, проведения акций "подарок за покупку", "лотерея" и т.д.
                  </Typography>
                </CardContent>
              </Card >
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <CardActionArea>
              <Card onClick={(goTradePromo)} variant="outlined" sx={{ minHeight: '320px' }} >
                <CardOverflow>
                  <img
                    src={trubaImage}
                    style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                    loading="lazy"
                    alt=""
                  />
                </CardOverflow>
                <CardContent>
                  <Typography level="h3">Trade Promotion</Typography>
                  <Typography level="body-sm">
                    Разработка, организация и проведение комплекса мероприятий стимулирования продаж на всех этапах дистрибуции.

                  </Typography>
                </CardContent>
              </Card >
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <CardActionArea>
              <Card onClick={(goMerchPromo)} variant="outlined" sx={{ minHeight: '320px' }}>
                <CardOverflow>
                  <img
                    src={merchImage}
                    style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                    loading="lazy"
                    alt=""
                  />
                </CardOverflow>
                <CardContent>
                  <Typography level="h3">Merchandising</Typography>
                  <Typography level="body-sm">
                    Организация и осуществление выкладки продукции в розничных магазинах (в супермаркетах,  гипермаркетах, cash & carry, дисконтах, магазинах у дома, прилавочного типа) согласно требованиям заказчика.
                  </Typography>
                </CardContent>
              </Card >
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <CardActionArea>
              <Card onClick={(goSecret)} variant="outlined" sx={{ minHeight: '320px' }}>
                <CardOverflow>
                  <img
                    src={eyeImage}
                    style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                    loading="lazy"
                    alt=""
                  />
                </CardOverflow>
                <CardContent>
                  <Typography level="h3">Mystery Shopping (Тайный покупатель)</Typography>
                  <Typography level="body-sm">
                    «Таинственный покупатель» – оценка соблюдения точками продаж заданных стандартов торговли и обслуживания клиентов.
                  </Typography>
                </CardContent>
              </Card >
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <CardActionArea>
              <Card onClick={(goDirectMarketing)} variant="outlined" sx={{ minHeight: '320px' }} >
                <CardOverflow>
                  <img
                    src={heartImage}
                    style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                    loading="lazy"
                    alt=""
                  />
                </CardOverflow>
                <CardContent>
                  <Typography level="h3">Direct Marketing и Loyalty Club</Typography>
                  <Typography level="body-sm">
                    Создание прямых маркетинговых коммуникаций персонально с каждым потребителем товаров и услуг Заказчика..
                  </Typography>
                </CardContent>
              </Card >
            </CardActionArea>
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <CardActionArea>
              <Card onClick={(goDevelopment)} variant="outlined" sx={{ minHeight: '320px' }}>
                <CardOverflow>
                  <img
                    src={devImage}
                    style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                    loading="lazy"
                    alt=""
                  />
                </CardOverflow>
                <CardContent>
                  <Typography level="h3">Разработка и поставка POSM</Typography>
                  <Typography level="body-sm">
                    Контролируем процесс производства с момента разработки эскиза и до отгрузки продукции заказчику, что позволяет поставлять продукцию высокого качества.
                  </Typography>
                </CardContent>
              </Card >
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <CardActionArea>
              <Card onClick={(goConference)} variant="outlined" sx={{ minHeight: '320px' }}>
                <CardOverflow>
                  <img
                    src={confImage}
                    style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                    loading="lazy"
                    alt=""
                  />
                </CardOverflow>
                <CardContent>
                  <Typography level="h3">Организация конференций</Typography>
                  <Typography level="body-sm">
                    Разрабатываем, организуем и проводим конференции, конвенции, team building.
                  </Typography>
                </CardContent>
              </Card >
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <CardActionArea>
              <Card onClick={(goSpecial)} variant="outlined" sx={{ minHeight: '320px' }}>
                <CardOverflow>
                  <img
                    src={dimondImage}
                    style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                    loading="lazy"
                    alt=""
                  />
                </CardOverflow>
                <CardContent>
                  <Typography level="h3">Проведение спецпроектов</Typography>
                  <Typography level="body-sm">
                    Например в сотрудничестве с "Мегафон" организовали встречу с Паоло Коэльо.
                  </Typography>
                </CardContent>
              </Card >
            </CardActionArea>
          </Grid>
        </Grid >
      </Container >
    </Box >
  );
}
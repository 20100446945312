import React from 'react'
import { Typography, Box, Container, Grid, ImageList, ImageListItem } from '@mui/material'
import dimondImage from '../../../images/dimondImage.png'
import SPimage from '../../../images/SPimage.jpg'
import macImage from '../../../images/macImage.jpg'
import pirateImage from '../../../images/pirateImage.jpg'



const itemData = [
  {
    img: SPimage,
    title: 'SPimage',
  },
  {
    img: macImage,
    title: 'macImage',
  },
  {
    img: pirateImage,
    title: 'pirateImage',
  },
]

export default function Specialprojects() {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box sx={{ width: '100%', height: '100px', backgroundColor: '#3692E9', textAlign: 'center' }}>
        <Typography variant="h5" pt={4} weight='light' style={{ color: 'white', fontWeight: 'bold' }}>
          Наши услуги
        </Typography>
      </Box>

      <Container sx={{ width: '100%', minHeight: '100px', backgroundColor: '#FFFFFF' }}>
        <Grid container>
          <Grid item>
            <img
              src={dimondImage}
              style={{ maxWidth: '60px', maxHeight: '60px', margin: '30px' }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item pt={5}>
            <Typography variant="h4" style={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}>
              Проведение спецпроектов
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Typography variant='h5' color='black' textAlign={'center'} mt={2}>
          Проводим специальные проекты:
        </Typography>
        <Grid container mt={5}>
          <Grid item xs={12} md={6}>
            <Typography variant='h6' color='#838383'>
              <ul>
                <li>
                  Аптеках;
                </li>
                <li>
                  школах;
                </li>
                <li>
                  ВУЗах;
                </li>
                <li>
                  бизнесс-центрах;
                </li>
                <li>
                  кинотатрах;
                </li>
                <li>
                  театрах;
                </li>
                <li>
                  книжных магазинах;
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant='h6' color='#838383'>
              <ul>
                <li>
                  салонах красоты;
                </li>
                <li>
                  фитнесс-центрах;
                </li>
                <li>
                  ночных клубах;
                </li>
                <li>
                  парках отдыха;
                </li>
                <li>
                  автосалонах;
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
        <Typography variant='h5' color='black' textAlign={'center'} mt={5}>
          Презентация VIP аудитории предложений элитной загородной недвижимости компании «АЛЛТЕК Девелопмент» на Cottage Showroom.
        </Typography>
        <Typography variant='h6' color='#838383' mt={5} textAlign={'center'}>
          <b>Бренды:</b> элитные загородные посёлки «Графский Лес», «Графские пруды», аукцион www.bidonthecity.com.
        </Typography>
      </Container>

      <Container sx={{ mt: '20px' }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant='h6' color='#838383' sx={{ mt: '20px', fontWeight: 'bold' }}>
              Аудитория:
            </Typography>
            <Typography variant='h6' color='#838383'>
              <ul>
                <li>
                  Посетители Галереи «Времена Года»;
                </li>
                <li>
                  Клиенты Private Banking;
                </li>
                <li>
                  Гости ресторанов премиум-класса;
                </li>
                <li>
                  Читатели газет «Ведомости», «Деньги», «Финанс» и др.;
                </li>
                <li>
                  Слушатели радио Бизнес ФМ и т.п.
                </li>
              </ul>
            </Typography>
            <Typography variant='h6' color='#838383' sx={{ mt: '20px', fontWeight: 'bold' }}>
              Выборочные результаты:
            </Typography>
            <Typography variant='h6' color='#838383' mb={10}>
              Свыше 1 000 представителей VIP аудитории получили от консультантов нашего агентства информацию о поселках «Графский лес», «Графские пруды», предложениях «АЛЛТЕК Девелопмент», аукционе www.bidonthecity.com.
              Некоторые люди, получившие информацию от консультантов агентства, стали покупателями элитной загородной недвижимости.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid >
              <Typography variant='h6' fontWeight={'bold'} color='#838383' mt={'20px'}>
                Встреча с Паоло Коэльо в Москве
              </Typography>
              <Typography variant='h6' color='#838383' mt={5}>
                Наше агентство было организатором спонсорского участия бренда "Мегафон" в единственной в Москве встрече читателей с автором всемирно известных бестселлеров - Пауло Коэльо в книжном магазине «Библио-Глобус».
                Встреча с Пауло Коэльо вызвала ажиотаж среди читателей, автор подписал более 700 книг почитателям своего таланта, был весьма энергичен, шутил, пожимал руки.
              </Typography>

              <Typography variant='h6' fontWeight={'bold'} color='#838383' mt={'20px'}>
                Промоподдержка участия компании «Ehrmann»
                в Турнире по гольфу компании «Макдоналдс»
              </Typography>
              <Typography variant='h6' color='#838383' mt={'20px'}>
                Участники Турнира по гольфу познакомились с новинками компании «Ehrmann».

                Сотрудники компании "Макдоналдс", Благотворительного фонда Роналда Макдоналда неоднократно высказывали слова благодарности компании «Ehrmann» за оказываемую поддержку инициатив компании "Макдоналдс".

                На наш взгляд, участие компании «Ehrmann» в данном мероприятии, способствует развитию дружеских отношений с компанией «Макдоналдс», повышению лояльности со стороны компании «Макдоналдс».

                Как следствие, ожидается, что в результате проведения данной акции  повысится объем продаж продукции компании «Ehrmann» клиентам «Макдоналдс».
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container >

      <Container sx={{ mt: '50px' }}>
        <Typography variant='h5' fontWeight='bold'>
          Примеры работ:
        </Typography>
        <ImageList sx={{ width: '100%', height: '100%', mb: 5, mt: 5 }} variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=161&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Container>

    </Box>
  )
}

import React from 'react'
import { Paper, Box, Grid, Typography, Container } from '@mui/material';
import image from '../../../images/Screenshot_2.png'




export default function PictureCard() {
  return (
    <>
      <Box>
        <Paper sx={{
          backgroundImage: `url(${image})`, height: '600px', width: '100vw', backgroundPosition: "center", backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}>
          <Container sx={{minWidth: '400px'}}>
            <Typography variant='h3' sx={{ pt: 30, textAlign: 'center', alignContent: 'center', fontWeight: 'bold', color: 'white' }}>
              Brand progress
            </Typography>
            <Typography variant='h5' sx={{ pt: 1, textAlign: 'center', color: 'white' }}>
              Разработка, организация и проведение комплекса мероприятий стимулирования продаж на всех этапах дистрибуции.
            </Typography>
            <Typography variant='h5' sx={{ pt: 1, textAlign: 'center', fontWeight: 'bold', alignContent: 'center', color: 'white' }}>
              Быстро. Качественно.
            </Typography>
          </Container>
        </Paper>
      </Box>
    </>
  );
}


import React from "react";
import { Typography, Box, Container } from "@mui/material";

const Contacts = () => {
  return (

    <Box sx={{ minHeight: '100vh' }}>
      <Box sx={{ width: '100%', height: '100px', backgroundColor: '#3692E9', alignItems: 'center' }}>
        <Typography variant="h5" pt={4} weight='light' style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>
          Контакты
        </Typography>
      </Box>

      <Container sx={{mt: '50px'}}>
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>
          Телефон:
        </Typography>
        <Typography variant="h6">
          +7 (499) 136-8709
        </Typography>
      </Container>
    </Box>

  );
};

export default Contacts;

import * as React from 'react';
import Grid from '@mui/joy/Grid';
import { Typography, Container, Card, CardContent, CardActions, Box } from '@mui/material';
import danoneImage from '../../../images/Danone.jpg'
import lindtImage from '../../../images/lindt.jpg'
import ehrmannImage from '../../../images/Ehrmann.jpg'
import johnsonandjohnsonImage from '../../../images/johnsonandjohnson.jpg'
import otcPharm from '../../../images/otcpharm.jpg'
import pyatoro4ka from '../../../images/5ro4ka.jpg'
import megaphoneImage from '../../../images/megaphone.jpg'
import agroProm from '../../../images/agroprom.jpg'
import ermigurtImage from '../../../images/Ermigurt.jpg'
import unionImage from '../../../images/foodunion.jpg'
import marevenImage from '../../../images/mareven.jpg'

const size = 140
const md = 2
const sm = 3
const xs = 5

export default function PartnersBlock() {
  return (
    <Box sx={{ backgroundColor: '#FFFFFF' }}>
      <Container sx={{ pt: 10, pb: 8 }} >
        <Grid container spacing={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid item xs={xs} sm={sm} md={md}>
            <img
              src={danoneImage}
              style={{ maxWidth: `${size}px`, maxHeight: `${size}px`, justifyContent: 'center' }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item xs={xs} sm={sm} md={md}>
            <img
              src={ehrmannImage}
              style={{ maxWidth: `${size}px`, maxHeight: `${size}px` }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item xs={xs} sm={sm} md={md}>
            <img
              src={otcPharm}
              style={{ maxWidth: `${size}px`, maxHeight: `${size}px` }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item xs={xs} sm={sm} md={md}>
            <img
              src={johnsonandjohnsonImage}
              style={{ maxWidth: `${size}px`, maxHeight: `${size}px` }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item xs={xs} sm={sm} md={md}>
            <img
              src={pyatoro4ka}
              style={{ maxWidth: `${size}px`, maxHeight: `${size}px` }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item xs={xs} sm={sm} md={md}>
            <img
              src={agroProm}
              style={{ maxWidth: `${size}px`, maxHeight: `${size}px` }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item xs={xs} sm={sm} md={md}>
            <img
              src={megaphoneImage}
              style={{ maxWidth: `${size}px`, maxHeight: `${size}px` }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item xs={xs} sm={sm} md={md}>
            <img
              src={lindtImage}
              style={{ maxWidth: `${size}px`, maxHeight: `${size}px` }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item xs={xs} sm={sm} md={md}>
            <img
              src={marevenImage}
              style={{ maxWidth: `${size}px`, maxHeight: `${size}px` }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item xs={xs} sm={sm} md={md}>
            <img
              src={unionImage}
              style={{ maxWidth: `${size}px`, maxHeight: `${size}px` }}
              loading="lazy"
              alt=""
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )}
import danoneImage from '../images/Danone.jpg'
import ehrmannImage from '../images/Ehrmann.jpg'
import johnsonandjohnsonImage from '../images/johnsonandjohnson.jpg'
import caprisunImage from '../images/caprisun.png'
import agroprom from '../images/agroprom.jpg'
import univit from '../images/univit.jpg'
import lindt from '../images/lindt.jpg'
import foodunion from '../images/foodunion.jpg'
import marevenfood from '../images/marevenfood.jpg'

export const aboutDataCompany = {
  company: [
    {
      img: ehrmannImage,
      year: 'С 2006 г.',
      text: " на постоянной основе, мы сотрудничаем с компанией «Ehrmann». Разрабатываем, организовываем и проводим для них trade marketing & consumer promotion, поставляем промооборудование, призовой фонд и униформу для проведения промоушн - акций. Мы рекламируем молочную продукцию брендов «Select», «А - ля Греческий», «Эрмигурт», «Эрмигурт Fresh», «Pre - biotic», пудинги «Grand Dessert» и «Robby for you», продукцию для детей бренда «Эрмик»."
    },
    {
      img: danoneImage,
      year: 'С 2007 г.',
      text: " мы сотрудничали с компанией «DANONE», для которой осуществляли разработку и поставку торгового оборудования, POSM, униформы, призового фонда для промоушн- акций, уникальной сувенирной продукции, а также проводили нестандартные рекламные акции (например, в магазинах врачи - педиатры нашего агентства рассказывают молодым мамам о правилах первого прикорма, дают консультации). Наше агентство продвигало такие бренды компании как «Активиа», «Простоквашино», «Actimel», «Віо Баланс», «Растишка», «Актуаль», «Для всей семьи», «Летний день», «Тёма», «Смешарики»"
    },
    {
      img: caprisunImage,
      year: 'С 2013 г.',
      text: ' мы продвигали на российском рынке соковую продукцию бренда «Capri - Sun». В рамках рекламной акции мы размещали наружную и ТВ рекламу, разрабатывали и поставляли торговое оборудование, рекламные материалы для мест продаж, униформу, проводили trade & consumer promotion, event.'
    },
    {
      img: marevenfood,
      year: 'В 2013 г.',
      text: ' нашим новым клиентом стала компания «MAREVEN FOOD CENTRAL», занимающая лидирующие позиции на российском рынке продуктов быстрого приготовления, прежде всего вермишели, лапши, картофельного пюре и бульонов таких брендов как «Роллтон», «Big Bon», «Rolben», «RolRol», «Петра»'
    },
    {
      img: univit,
      year: 'С 2014 г.',
      text: ' мы рекламируем витаминные комплексы «ЮНИВИТ», поставляем промостройки, униформу для промоакций'
    },
    // {
    //     img: lindt,
    //     year: 'С 2015 года.',
    //     text: 'В преддверии 8 марта, мерчандайзеры нашего агентства осуществляют дополнительную выкладку продукции компании Lindt & Sprüngli, а юноши - шоколатье рекламируют восхитительные шоколадные конфеты Lindor'
    // },
    {
      img: agroprom,
      year: 'В 2015 г.',
      text: ' мы начали сотрудничество с группой компаний «АгроПромкомплектация». Для этого заказчика мы разрабатываем и проводим бесконтактные рекламные акции, поставляем униформу, сувенирную продукцию по брендам «Дмитрогорский Продукт» «Искренне Ваш», «Ближние горки». Организовываем и проводим рекламные акции по продвижению фермерских лавок «Ближние Горки» в бизнес - центрах.'
    },
    {
      img: johnsonandjohnsonImage,
      year: '',
      text: 'Одним из наших клиентов является компания «Johnson&Johnson», для которой мы разработали и провели такие trade marketing-проекты по бренду «Compeed», как акции «Таинственный покупатель»; осуществляли разработку и поставку рекламных материалов, призового фонда по брендам «Nicorette», «Visine», «Тизин»'
    },
  ],
  nav: 'Рекламное агентство «Бренд прогресс» создано в 2006 году.',

  context: 'Президентским советом Фонда поддержки предпринимательских инициатив наше агентство выдвинуто номинантом премии «Компания N°1» 2015 с присуждением почетного звания «НАДЕЖНЫЙ ПОСТАВЩИК ПРОДУКЦИИ И УСЛУГ» и вручением знака «За вклад в развитие национальной экономики. С 2009 г. по настоящее время, наше агентство «Бренд прогресс» оценивается как «Бронза BTL» «среди популярных игроков рынка маркетинговых услуг (BTL) России» в некоммерческом профессиональном конкурс выбора BTL агентств»*. Основными принципами оценки агентств являются соотношение «цены и качества», «надежности и профессионализма», «креативн эффективности».',

  contextWithStar: '*НПП «Дни выбора BTL агентств» - это профессиональный конкурс, в котором выделяют лучшие агентства Мо регионов на основании практического опыта сотрудничества игроков рынка маркетинговых услуг (BTL). работаю территории России.'
}

import React from 'react'
import { Typography, Box, Container, Grid, ImageList, ImageListItem } from '@mui/material'
import merchImage from '../../../images/merchImage.png'
import merch1 from '../../../images/merch1.jpg'
import merch2 from '../../../images/merch2.jpg'
import merch3 from '../../../images/merch3.jpg'
import merch4 from '../../../images/merch4.jpg'


const itemData = [
  {
    img: merch2,
    title: 'merch1',
  },
  {
    img: merch4,
    title: 'merch2',
  },
  {
    img: merch1,
    title: 'merch3',
  },
  {
    img: merch3,
    title: 'merch4',
  },
]


export default function Merch() {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box sx={{ width: '100%', height: '100px', backgroundColor: '#3692E9', textAlign: 'center' }}>
        <Typography variant="h5" pt={4} weight='light' style={{ color: 'white', fontWeight: 'bold' }}>
          Наши услуги
        </Typography>
      </Box>

      <Container sx={{ width: '100%', minHeight: '100%', backgroundColor: '#FFFFFF' }}>
        <Grid container>
          <Grid item>
            <img
              src={merchImage}
              style={{ maxWidth: '60px', maxHeight: '60px', margin: '30px' }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item pt={5} >
            <Typography variant="h4" style={{ color: 'black', fontWeight: 'bold', alignItems: 'center' }}>
              Мерчандайзинг
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ mt: '30px' }}>
        <Grid container justifyContent="space-between">
          <Grid sx={{ float: 'left', width: '500px', }}>
            <Typography variant='h6' color='#838383'>
              <ul>
                <li>Создание планограмм, мерчандайзинг-стандартов.</li>
                <li>Профессиональный отбор и подготовка мерчандайзингового персонала.</li>
                <li>Организация и осуществление выкладки продукции в любом формате розничных магазинов (в супермаркетах,  гипермаркетах, cash & carry, дискаунтерах, магазинах у дома, прилавочного типа) согласно требованиям заказчика.</li>
                <li>Постоянный контроль деятельности мерчандайзеров.</li>
                <li>Исследование влияния мерчандайзинга на продажи.</li>

              </ul>
            </Typography>
          </Grid>
          <Grid sx={{ float: 'left', width: '500px' }}>
            <Typography variant='h6' color='#838383'>
              <ul>
                <li>Сбор, анализ и составление отчетов по остаткам товаров, подготовка рекомендованных
                  и переводных заказов.</li>
                <li>Создание и поддержание благоприятной репутации компании клиента в глазах покупателей, администрации и персонала магазинов;</li>
                <li>Проведение тренингов по продукции заказчика для персонала магазинов;</li>
                <li>Оформление и контроль возврата-обмена товара;</li>
                <li>Стимулирование продаж  продукции.</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: '50px' }}>
        <Typography variant='h5' fontWeight='bold'>
          Примеры работ:
        </Typography>
        <ImageList sx={{ width: '100%', height: '100%', mb: 5, mt: 5 }} variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=161&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Container>
    </Box >
  )
}

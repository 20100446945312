import React from "react";
import { Typography, Box, Container, Grid, Divider, Card } from "@mui/material";
import AboutItem from "../About/AboutItem";
import { aboutDataCompany } from "../../storage/About";
import graf from '../../images/grafik.jpg'
import NationalAward from '../../images/NationalAward.jpg'
import { useState } from "react";


const About = () => {

  const [open, setOpen] = useState(false)

  return (
    <>
      <Box sx={{ width: '100%', height: '100%', backgroundColor: '#3692E9' }}>
        <Container>
          <Typography variant="h6" color='white' pt={3} pb={3} sx={{ textAlign: 'center' }} >
            {aboutDataCompany.nav}
          </Typography>
        </Container>
      </Box>
      <Container>
        <Box sx={{ backgroundColor: '#F5F5F5' }} p={5} mb={6} mt={3}>
          <Typography>
            {aboutDataCompany.context}
          </Typography>
          <Typography color='#838383' sx={{ mt: '20px' }}>
            {aboutDataCompany.contextWithStar}
          </Typography>
        </Box>
        <Grid container sm={12} md={12} mb={6}>
          {aboutDataCompany.company.map((e, index) => <AboutItem key={index} img={e.img} year={e.year} text={e.text} />)}
        </Grid>
        <Grid container sx={{ justifyContent: 'center', mb: 10 }}>
          <Typography variant="h6">
            Мы выполняем срочные заказы и выполняем их оперативно!
          </Typography>
        </Grid>
        <Divider />
        <Grid container mb={3} display={'flex'} justifyContent={'space-around'}>
          <Grid item xs={12} sm={12} md={12} >
            <Card>
              <Container>
                <Typography variant="h5" m={2}>За последний год наше агентство было наиболее востребовано в следующих отраслях</Typography>
                <Typography mb={3}>• Проведение промоушн акций;</Typography>
                <Typography mb={3}>• Поставка рекламных материалов, оборудования POSM;</Typography>
                <Typography mb={3}>• Поставка призов для стимулирования представителей цепочки дистрибуции (партнеров заказчика, торговых представителей, мерчандайзеров);</Typography>
                <Typography mb={3}>• Разработка и поставка униформы;</Typography>
                <Typography mb={3}>• Разработка и поставка расходных материалов для проведения промоушн акций (например, брендованной посуды)</Typography>
              </Container>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Container sx={{ minWidth: '100%', height: "100%", mt: 6, mb: 3 }}>
              <img
                src={graf}
                style={{ maxWidth: '100%', maxHeight: '100%' }}
                loading="lazy"
                alt="х"
              />
            </Container>
          </Grid>
          <Divider />

          <Grid item xs={12} sm={12} md={12}>
            <Container sx={{ mt: 6, mb: 3 }}>
                <img
                  src={NationalAward}
                  className={open ? "full" : "preview" }
                  onClick={() => setOpen(true)}
                  loading="lazy"
                  alt="х"
                />
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default About;

import React from "react";
import { Typography, Box, Grid, Container } from "@mui/material";

const Instruments = () => {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box sx={{ width: '100%', height: '100px', backgroundColor: '#3692E9', alignItems: 'center', justifyContent: 'center'  }}>
        <Typography variant="h5" pt={4} weight='light' style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>
          Инструменты
        </Typography>
      </Box>
      <Container>
        <Container sx={{ mt: '30px' }}>
          <Grid container justifyContent="space-between">
            <Grid sx={{ float: 'left', width: '500px', }}>
              <Typography variant='h5' color='black' sx={{ fontWeight: 'bold'}}>
                Оценка эффективности
                рекламных акций
              </Typography>
              <Typography variant='h6' color='#838383'>
                <ul>
                  <li>  При проведении рекламных акций мы всегда измеряем влияние проводимой акции на продажи промоутируемой продукции*. </li>
                  <li> Выясняется вопрос, влияет ли рекламная акция на покупку промоутируемой продукции, если да, то почему, если нет, то почему.</li>
                  <li>На основании исследования устанавливаются достоинства и недостатки промоакции, в случае необходимости в промоакцию вносятся необходимые корректировки.</li>
                  <li>Это позволяет проводить гарантированно действенные, эффективные рекламные акции. </li>
                </ul>
              </Typography>
            </Grid>
            <Grid sx={{ float: 'left', width: '500px' }}>
              <Typography variant='h5' color='black' sx={{ fontWeight: 'bold' }}>
                Контроль работы персонала
              </Typography>
              <Typography variant='h6' color='#838383'>
                <ul>
                  <li>Помимо супервайзеров, осуществляющих контроль работы персонала, в нашей компании проводится «двойной полевой аудит» проведения промо акций.</li>
                  <li>На ряду с аудитором - «таинственным покупателем», проверяющим выполнение промоперсоналом поставленных целей и задач, и остающимся инкогнито для промоутера,
                    в аудите принимает участие профессиональный психолог. В задачи психолога входит оценка умений и навыков работы сотрудника в «поле», при необходимости обучение и коррекция действий персонала на основании наблюдений, сделанных во время визита.</li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container sx={{ mt: '50px', mb: '40px'}}>
          <Typography color='#838383'>
            *Мы предлагаем своим клиентам до проведения рекламной акции проводить пробные акции на репрезентативной выборке магазинов города,
            с целью выяснения влияния данной акции на продажи и при необходимости внесения в нее корректировок. Данное исследование способствует
            увеличению эффективности и оптимизации бюджета проведения масштабной рекламной акции.
          </Typography>
        </Container>
      </Container>
    </Box >



  );
};

export default Instruments;

import React from 'react'
import { Typography, Box, Container, Grid, ImageList, ImageListItem } from '@mui/material'
import discontImage from '../../../images/discountImage.png'
import foto1 from '../../../images/foto1.jpg'
import foto2 from '../../../images/foto2.jpg'
import foto3 from '../../../images/foto3.jpg'
import foto4 from '../../../images/foto4.jpg'
import foto5 from '../../../images/foto5.jpg'



const itemData = [
  {
    img: foto1,
    title: 'foto1',
  },
  {
    img: foto2,
    title: 'foto2',
  },
  {
    img: foto3,
    title: 'foto3',
  },
  {
    img: foto5,
    title: 'foto4',
  },
  {
    img: foto4,
    title: 'foto5',
  },
]

export default function Consumerpromo() {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box sx={{ width: '100%', height: '100px', backgroundColor: '#3692E9', alignItems: 'center' }}>
        <Typography variant="h5" pt={4} weight='light' style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>
          Наши услуги
        </Typography>
      </Box>

      <Container sx={{ width: '100%', minHeight: '100px', backgroundColor: '#FFFFFF' }}>
        <Grid container>
          <Grid item>
            <img
              src={discontImage}
              style={{ maxWidth: '60px', maxHeight: '60px', margin: '30px' }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item pt={5}>
            <Typography variant="h4" style={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}>
              Consumer promo
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ mt: '30px' }}>
        <Grid container justifyContent="space-between">
          <Grid sx={{ float: 'left', width: '500px', }}>
            <Typography variant='h6' color='#838383'>
              <ul>
                <li>Раздача листовок, в том числе организация работы "людей-указателей";</li>
                <li>Самплинги;</li>
                <li>Дегустации;</li>
                <li>Работа на выставках, презентациях, демонстрация товараж;</li>
                <li>Персональная пропаганда;</li>
                <li>Консультирование;</li>
              </ul>
            </Typography>
          </Grid>
          <Grid sx={{ float: 'left', width: '500px', mb: '30px' }}>
            <Typography variant='h6' color='#838383'>
              <ul>
                <li>Провокационный маркетинг;</li>
                <li>Организация и проведение бренд-вечеринок;</li>
                <li>Организация шоу-программ;</li>
                <li>Стимулирование продаж посредством организации Центров выдачи призов, проведения акций "подарок за покупку", "лотерея" и т.д.</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: '50px' }}>
        <Typography variant='h5' fontWeight='bold'>
          Примеры работ:
        </Typography>
        <ImageList sx={{ width: '100%', height: '100%', mb: 5, mt: 5 }} variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=161&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Container>
    </Box>
  )
}

import React from "react";
import { Typography, Box } from "@mui/material";

const News = () => {
  return (
    <Box sx={{minHeight: '100vw'}}>
      <Box sx={{ width: '100%', height: '100px', backgroundColor: '#3692E9'}}>
        
      </Box>    
    </Box>
  );
};

export default News;

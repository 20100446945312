import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Lato from '../../fonts/Lato-Light.woff2';

const pages = ['О КОМПАНИИ', 'УСЛУГИ', 'НАШИ КЛИЕНТЫ', 'ИНСТРУМЕНТЫ', 'КОНТАКТЫ'];
const settings = ['about', 'servises', 'ourclients', 'instruments', "contacts"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ background: '#0E263E', display: 'flex' }}>
      <Box sx={{display: 'flex'}} >
        <Toolbar sx={{ width: '100%' }}>
          <Link to='/' style={{ textDecoration: 'none', flexGrow: 1 }}>
            <Typography variant="h5" sx={{ display: { xs: 'none', md: 'flex' }, color: 'white', fontFamily: Lato }}>
              Brand progress
            </Typography>
          </Link>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' }, }}
            >
              {pages.map((page, index) => (
                <Link style={{ textDecoration: 'none', color: "black" }} to={`/${settings[index]}`}>
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    {page}
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <Link to='/' style={{ textDecoration: 'none', flexGrow: 1 }}>
            <Typography variant="h6" component='a' href="/" sx={{ display: { xs: 'flex', md: 'none' }, color: 'white', textDecoration: 'none', fontFamily: Lato }}>
              Brand progress
            </Typography>
          </Link>
          <Box sx={{ display: { xs: 'none', md: 'flex' }}}>
            {pages.map((page, index) => (
              <Link style={{ textDecoration: 'none', color: "white" }} to={`/${settings[index]}`}>
                <Button key={page} onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                  {page}
                </Button>
              </Link>
            ))}
          </Box>
          <Box>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right', }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center"></Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}
export default ResponsiveAppBar;
import React from 'react';
import { BrowserRouter, } from 'react-router-dom';
import ResponsiveAppBar from './components/Navbar/appbar';
import ResponsiveFooterBar from './components/Footer/footer';
import './App.css'
import AppRouter from './components/Routing/AppRouter';
import ScrollToTop from './components/Ui/ScrollToTop';




function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <ScrollToTop />
        <ResponsiveAppBar />
        <AppRouter />
        <ResponsiveFooterBar />
      </BrowserRouter>
    </div>
  );
}

export default App;

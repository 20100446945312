import React from "react";
import { Typography, Box, Grid, Card, Container } from "@mui/material";
import { CardOverflow } from "@mui/joy";

const AboutItem = ({ img, year, text }) => {
  return (
    <Grid container display={"flex"}>

      <Grid item xs={12} sm={12} md={12} sx={{ mt: '20px', width: '100%' }}>
        <Card variant="outlined" sx={{ height: '100%' }}>
          <CardOverflow sx={{ justifyContent: 'center', pt: '20px' }}>
            <img
              src={img}
              style={{ maxWidth: '140px', maxHeight: '140px' }}
              loading="lazy"
              alt="х"
            />
          </CardOverflow>

          <Container sx={{ pb: '30px' }}>

            <Typography style={{ textAlign: 'justify' }}>
              <b>{year}</b>{text}
            </Typography>
          </Container>
        </Card>
      </Grid>

    </Grid >
  );
};

export default AboutItem;

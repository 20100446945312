import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import { Grid, Box, IconButton, Typography, Toolbar, Container, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Lato from '../../fonts/Lato-Light.woff2';

const pages = ['О КОМПАНИИ', 'УСЛУГИ', 'НАШИ КЛИЕНТЫ', 'ИНСТРУМЕНТЫ', 'КОНТАКТЫ'];
const settings = ['about', 'servises', 'ourclients', 'instruments', "contacts"];

function ResponsiveFooterBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ background: '#3E3E3E', left: "0", bottom: "0", width: "100%", height: "140px" }}>
      <Container >
        <Toolbar sx={{ display: 'flex', width: '100%' }}>
          <Link to='/' style={{ textDecoration: 'none', flexGrow: 1 }}>
            <Typography variant="h5" sx={{ display: { xs: 'none', md: 'flex' }, color: 'white', fontFamily: Lato }}>
              Brand progress
            </Typography>
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              keepMounted
              transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' }, }}
            >
              {pages.map((page, index) => (
                <Link style={{ textDecoration: 'none', color: "black" }} to={`/${settings[index]}`}>
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    {page}
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <Link to='/' style={{ textDecoration: 'none', flexGrow: 1 }}>
            <Typography variant="h6" sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1, color: 'white', fontFamily: Lato }}>
              Brand progress
            </Typography>
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, index) => (
              <Link style={{ textDecoration: 'none', color: "white" }} to={`/${settings[index]}`}>
                <Button key={page} onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                  {page}
                </Button>
              </Link>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right', }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center"></Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <Container sx={{ mt: '15px' }}>
        <Grid container display='flex'>
          <Grid sm={6}>
            <Typography fontSize='13px'>
              © Brand progress., 2006-2023 Все права защищены.
            </Typography>
          </Grid>
          <Grid sm={6}>
            <Typography>
              <Link style={{ textDecoration: 'none', fontFamily: Lato, color: 'white', fontSize: '13px' }}>Пользовательское соглашение</Link>
              <span> | </span>
              <Link style={{ textDecoration: 'none', fontFamily: Lato, color: 'white', fontSize: '13px' }}>Правовая информация</Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
}
export default ResponsiveFooterBar;
import React from "react";
import { Typography, Box, Grid, Container, Card, CardActionArea } from "@mui/material";
import { CardOverflow } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import discontImage from '../../images/discountImage.png'
import dimondImage from '../../images/dimondImage.png'
import merchImage from '../../images/merchImage.png'
import eyeImage from '../../images/eyeImage.png'
import confImage from '../../images/confImage.png'
import trubaImage from '../../images/trubaImage.png'
import devImage from '../../images/devImage.png'
import heartImage from '../../images/heartImage.png'


const Servises = () => {
  const navigate = useNavigate();
  const goConsumerPromo = () => navigate('/consumerpromo')
  const goTradePromo = () => navigate('/tradepromo')
  const goMerchPromo = () => navigate('/merch')
  const goSecret = () => navigate('/secret')
  const goDirectMarketing = () => navigate('/direct')
  const goDevelopment = () => navigate('/development')
  const goConference = () => navigate('/conference')
  const goSpecialProjects = () => navigate('/specialprojects')


  return (
    <Box sx={{minHeight: '100vh'}}>
      <Box height={100} sx={{ width: '100%', backgroundColor: '#3692E9', alignItems: 'center' }}>
        <Typography variant="h5" pt={4} style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>
          Наши услуги
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: 'white' }}>
        <Container sx={{ pt: 5, pb: 5, mt: 8}}>
          <Grid container spacing={2} sx={{ justifyContent: 'center', }}>
            <Grid item>
              <CardActionArea>
                <Card onClick={(goConsumerPromo)} sx={{ width: '300px', height: '300px', pt: '40px' }} alignItems='center'>

                  <CardOverflow sx={{ justifyContent: 'center' }}>
                    <img
                      src={discontImage}
                      style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                      loading="lazy"
                      alt=""
                    />
                  </CardOverflow>
                  <CardOverflow sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" component="div">
                      Consumer promo
                    </Typography>
                  </CardOverflow>

                </Card>
              </CardActionArea>
            </Grid>



            <Grid item >
              <CardActionArea>
                <Card onClick={(goTradePromo)} sx={{ width: '300px', height: '300px', pt: '40px' }}>
                  <CardOverflow sx={{ justifyContent: 'center' }}>
                    <img
                      src={trubaImage}
                      style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                      loading="lazy"
                      alt=""
                    />
                  </CardOverflow>
                  <CardOverflow sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" component="div">
                      Trade promo
                    </Typography>
                  </CardOverflow>
                </Card>
              </CardActionArea>
            </Grid>
            <Grid item >
              <CardActionArea>
                <Card onClick={(goMerchPromo)} sx={{ width: '300px', height: '300px', pt: '40px' }}>
                  <CardOverflow sx={{ justifyContent: 'center' }}>
                    <img
                      src={merchImage}
                      style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                      loading="lazy"
                      alt=""
                    />
                  </CardOverflow>
                  <CardOverflow sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" component="div">
                      Мерчендайзинг
                    </Typography>
                  </CardOverflow>
                </Card>
              </CardActionArea>
            </Grid>
            <Grid item >
              <CardActionArea>
                <Card onClick={(goSecret)} sx={{ width: '300px', height: '300px', pt: '40px' }}>
                  <CardOverflow sx={{ justifyContent: 'center' }}>
                    <img
                      src={eyeImage}
                      style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                      loading="lazy"
                      alt=""
                    />
                  </CardOverflow>
                  <CardOverflow sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" component="div">
                      Тайный покупатель
                    </Typography>
                  </CardOverflow>
                </Card>
              </CardActionArea>
            </Grid>
            <Grid item >
              <CardActionArea>
                <Card onClick={(goDirectMarketing)} sx={{ width: '300px', height: '300px', pt: '40px' }}>
                  <CardOverflow sx={{ justifyContent: 'center' }}>
                    <img
                      src={heartImage}
                      style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                      loading="lazy"
                      alt=""
                    />
                  </CardOverflow>
                  <CardOverflow sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ margin: "0px auto", width: '200px', textAlign: 'center' }}>
                      Direct Marketing и Loyalty Club
                    </Typography>
                  </CardOverflow>
                </Card>
              </CardActionArea>
            </Grid>
            <Grid item >
              <CardActionArea>
                <Card onClick={(goDevelopment)} sx={{ width: '300px', height: '300px', pt: '40px' }}>
                  <CardOverflow sx={{ justifyContent: 'center' }}>
                    <img
                      src={devImage}
                      style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                      loading="lazy"
                      alt=""
                    />
                  </CardOverflow>
                  <CardOverflow sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ margin: "0px auto", width: '200px', textAlign: 'center' }}>
                      Разработка/поставка POSM, униформы, оборудования
                    </Typography>
                  </CardOverflow>
                </Card>
              </CardActionArea>
            </Grid>
            <Grid item >
              <CardActionArea>
                <Card onClick={(goConference)} sx={{ width: '300px', height: '300px', pt: '40px' }}>
                  <CardOverflow sx={{ justifyContent: 'center' }}>
                    <img
                      src={confImage}
                      style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                      loading="lazy"
                      alt=""
                    />
                  </CardOverflow>
                  <CardOverflow sx={{ margin: "0px auto", width: '100px', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" component="div">
                      Организация конференций
                    </Typography>
                  </CardOverflow>
                </Card>
              </CardActionArea>
            </Grid>
            <Grid item >
              <CardActionArea>
                <Card onClick={(goSpecialProjects)} sx={{ width: '300px', height: '300px', pt: '40px' }}>
                  <CardOverflow sx={{ justifyContent: 'center' }}>
                    <img
                      src={dimondImage}
                      style={{ maxWidth: '60px', maxHeight: '60px', margin: '20px' }}
                      loading="lazy"
                      alt=""
                    />
                  </CardOverflow>
                  <CardOverflow sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ margin: "0px auto", width: '200px', textAlign: 'center' }}>
                      Проведение спецпроектов
                    </Typography>
                  </CardOverflow>
                </Card>
              </CardActionArea>
            </Grid>
          </Grid>
        </Container >
      </Box>
    </Box>
  );
};

export default Servises;

import React from 'react'
import { Typography, Box, Container, Grid, ImageList, ImageListItem } from '@mui/material'
import trubaImage from '../../../images/trubaImage.png'
import trade1 from '../../../images/trade1.jpg'
import trade2 from '../../../images/trade2.jpg'
import trade3 from '../../../images/trade3.jpg'
import trade4 from '../../../images/trade4.jpg'
import trade5 from '../../../images/trade5.jpg'

const itemData = [
  {
    img: trade1,
    title: 'trade1',
  },
  {
    img: trade2,
    title: 'trade2',
  },
  {
    img: trade3,
    title: 'trade3',
  },
  {
    img: trade5,
    title: 'trade4',
  },
  {
    img: trade4,
    title: 'trade5',
  },
]

export default function Tradepromo() {
  return (
    <Box sx={{minHeight: '100vh'}}>
      <Box sx={{ width: '100%', height: '100px', backgroundColor: '#3692E9', textAlign: 'center' }}>
        <Typography variant="h5" pt={4} weight='light' style={{ color: 'white', fontWeight: 'bold' }}>
          Наши услуги
        </Typography>
      </Box>

      <Container sx={{ width: '100%', height: '100px', backgroundColor: '#FFFFFF' }}>
        <Grid container>
          <Grid item>
            <img
              src={trubaImage}
              style={{ maxWidth: '60px', maxHeight: '60px', margin: '30px' }}
              loading="lazy"
              alt=""
            />
          </Grid>
          <Grid item pt={5}>
            <Typography variant="h4" sx={{ color: 'black', fontWeight: 'bold', alignItems: 'center' }}>
              Trade promo
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: '50px' }}>
        <Grid container justifyContent="space-between">
          <Grid sx={{ float: 'left', width: '400px', mb: '40px', mt: '40px'}}>
            <Typography variant='h6' color='#838383'>
              <ul>
                <li>Разработка, организация и проведение комплекса мероприятий стимулирования продаж на всех этапах дистрибуции</li>
              </ul>
            </Typography>
          </Grid>
          <Grid sx={{ float: 'left', width: '600px' }}>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
              НАПРИМЕР:
            </Typography>
            <Typography variant='h6' sx={{mt: '30px'}} color='#838383'>
              Для компании «Johnson & Johnson» мы проводили акции, направленные на обеспечение
              представленнности линии пластырей и косметики для ног бренда COMPEED® в аптеках 22 городов РФ,
              одновременно в 1582 точках, в течение 3 месяцев.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: '50px' }}>
        <Typography variant='h5' fontWeight='bold'>
          Примеры работ:
        </Typography>
        <ImageList sx={{ width: '100%', height: '100%', mb: 5, mt: 5 }} variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=161&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Container>
    </Box>
  )
}

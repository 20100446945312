import * as React from 'react';
import { styled } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Grid from '@mui/joy/Grid';
import { Typography, Container, Card, CardContent, CardActions, Box } from '@mui/material';

const Item = styled(Sheet)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
  ...theme.typography['body-sm'],
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius: 4,
  color: theme.vars.palette.text.secondary,
}));

export default function StatisticBlock() {
  return (
    <Box sx={{ backgroundColor: '#FFFFFF' }}>
      <Container sx={{ pt: 10, pb: 8 }} >
        <Grid container spacing={2} sx={{ flexGrow: 1, justifyContent: 'center', }}>
          <Grid item xs={6} sm={4} md={3}>
            <Typography variant='h3' color='#0088D1' textAlign='center'>300</Typography>
            <Typography textAlign='center'>счастливых клиентов</Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Typography variant='h3' color='#0088D1' textAlign='center'>17</Typography>
            <Typography textAlign='center'>городов-участников акции одновременно</Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Typography variant='h3' color='#0088D1' textAlign='center'>350-500</Typography>
            <Typography textAlign='center'>точек - средний <br/>охват промо акций</Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Typography variant='h3' color='#0088D1' textAlign='center'> 1000</Typography>
            <Typography textAlign='center'>база профессионального промо&#8209;персонала</Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
import React from 'react';
import { Typography, Box, Container, Grid, Paper } from "@mui/material";
import danoneImage from '../../images/Danone.jpg'
import rastishkaImage from '../../images/rastishka.jpg'
import ehrmannImage from '../../images/Ehrmann.jpg'
import johnsonandjohnsonImage from '../../images/johnsonandjohnson.jpg'
import caprisunImage from '../../images/caprisun.png'
import ecsmoImage from '../../images/ecsmo.jpg'
import megaphoneImage from '../../images/megaphone.jpg'
import biobalanceImage from '../../images/biobalance.jpg'
import graf from '../../images/grafpond.jpg'
import agroprom from '../../images/agroprom.jpg'
import univit from '../../images/univit.jpg'
import lindt from '../../images/lindt.jpg'
import foodunion from '../../images/foodunion.jpg'
import marevenfood from '../../images/marevenfood.jpg'
import actimel from '../../images/Actimel.jpg'
import select from '../../images/Select.jpg'
import rasa from '../../images/rasa.jpg'
import compeed from '../../images/compeed.jpg'
import ermigurt from '../../images/Ermigurt.jpg'
import activia from '../../images/activia.jpg'
import actual from '../../images/actual.jpg'
import legend from '../../images/legendugor.jpg'
import nicorette from '../../images/nicorette.jpg'
import prebiotic from '../../images/prebiotik.jpg'
import danissimo from '../../images/danissimo.jpg'
import tema from '../../images/tema.jpg'
import visine from '../../images/visine.jpg'
import ermil from '../../images/ermik.jpg'
import letnyday from '../../images/letniyday.jpg'
import madammu from '../../images/modammu.jpg'
import tizin from '../../images/Tizin.jpg'
import nikola from '../../images/nikola.jpg'
import smeshariki from '../../images/smeshariki.jpg'
import pyatorochka from '../../images/5ro4ka.jpg'
import cheremushki from '../../images/cheremushki.jpg'
import forallfamily from '../../images/forallfamily.jpg'
import alltech from '../../images/alltech.jpg'
import eksmo from '../../images/ecsmo.jpg'
import stok from '../../images/stockcentr.jpg'
import chernogolovka from '../../images/Chernogolovka.jpg'
import shustov from '../../images/shustov.jpg'
import uponor from '../../images/uponor.jpg'
import otcpharm from '../../images/otcpharm.jpg'
import grafles from '../../images/grafforest.jpg'
import gorki from '../../images/gorki.jpg'
import iskrenivash from '../../images/iskrenneyours.jpg'
import dmitrogor from '../../images/dmitrogorsky.jpg'
import provence from '../../images/provence.jpg'
import zalesskiy from '../../images/zalesskyfermer.jpg'
import ffff from '../../images/ffff.jpg'
import spmeat from '../../images/spmeat.jpg'


const brands = [danoneImage, rastishkaImage, ehrmannImage, ecsmoImage, johnsonandjohnsonImage,
  caprisunImage, megaphoneImage, biobalanceImage, graf, agroprom, univit, lindt, foodunion,
  marevenfood, actimel, select, rasa, compeed, ermigurt, activia, actual, legend, nicorette,
  prebiotic, danissimo, tema, visine, ermil, letnyday, madammu, tizin, nikola, smeshariki,
  pyatorochka, cheremushki, forallfamily, alltech, eksmo, stok, chernogolovka, shustov, uponor,
  grafles, gorki, iskrenivash, provence]


const size = 140

const OurClients = () => {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box sx={{ width: '100%', height: '100px', backgroundColor: '#3692E9', alignItems: 'center' }}>
        <Typography variant="h5" pt={4} style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>
          Бренды, которые мы продвигаем
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: '#FFFFFF' }}>
        <Container sx={{ pt: 10, pb: 8 }} >
          <Grid container spacing={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {brands.map((e) => (
              <Grid item xs={5} sm={3} md={2}>
                <img
                  src={e}
                  style={{ maxWidth: `${size}px`, maxHeight: `${size}px` }}
                  loading="lazy"
                  alt=""
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default OurClients;